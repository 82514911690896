import React from 'react'

import bees from './30_bee-loved-grau.jpg'
import karl from './karl-nickel.jpg'

export default [
  {
    title: 'Leer',
    description: 'Kein Stoff',
    className: 'default',
    previewImage: 'holder.js/100px180?text=%20&bg=ffffff',
    style: {
      fill: 'white'
    }
  },
  {
    title: 'Rot',
    description: 'Rote Fläche',
    className: 'red',
    previewImage: 'holder.js/100px180?text=%20&bg=ff0000',
    style: {
      fill: 'red'
    }
  },
  {
    title: 'Grün',
    description: 'Grüne Fläche',
    className: 'green',
    previewImage: 'holder.js/100px180?text=%20&bg=00ff00',
    style: {
      fill: 'url(#pattern-green)'
    },
    pattern: (
      <pattern id="pattern-green" x="2" y="2" width="6" height="6" patternUnits="userSpaceOnUse">
        <rect x="0" y="0" width="4" height="4" stroke="none" fill="#00ff00" />
      </pattern>)
  },
  {
    title: 'Bienen',
    description: 'French Terry - Bee loved grau ',
    className: 'french-terry-bee-loved-grau',
    previewImage: bees,
    style: {
      fill: 'url(#pattern-bees)'
    },
    pattern: (
      <pattern id="pattern-bees" x="0" y="0" width="100" height="100" patternUnits="userSpaceOnUse" patternContentUnits="userSpaceOnUse">
              <image href={bees} x="0" y="0" width="100" height="100"></image>
      </pattern>)
  },
  {
    title: 'Karl Nickel',
    description: 'Jersey - Karl Nickel',
    className: 'karl-nickel-jersey',
    previewImage: karl,
    style: {
      fill: 'url(#pattern-karl)'
    },
    pattern: (
      <pattern id="pattern-karl" x="0" y="0" width="300" height="300" patternUnits="userSpaceOnUse" patternContentUnits="userSpaceOnUse">
              <image href={karl} x="0" y="0" width="300" height="300"></image>
      </pattern>)
  }
]
