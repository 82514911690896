import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import Shirt from './templates/mütze-1'
import { SVGMap } from 'react-svg-map'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Modal from 'react-bootstrap/Modal'
import Navbar from 'react-bootstrap/Navbar'
import Card from 'react-bootstrap/Card'
import Logo from './logo.svg'
import patterns from './templates/patterns'
import { createUseStyles } from 'react-jss'

function ModalPatternChooser ({ show, hide, selectPattern }) {
  useEffect(() => {
    if (!show) return
    const script = document.createElement('script')
    script.src = 'https://cdnjs.cloudflare.com/ajax/libs/holder/2.9.7/holder.js'
    script.async = true
    document.body.appendChild(script)
    return () => {
      document.body.removeChild(script)
    }
  }, [show])
  return (
    <>

      <Modal
      show={show}
      onHide={hide}
      size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>Stoff auswählen</Modal.Title>
        </Modal.Header>
        <Modal.Body>
  <Row>
    {patterns.map(pattern => (
    <Col key={pattern.className}>
        <Card
          style={{
            width: '12rem',
            cursor: 'pointer'
          }}
          data-pattern={pattern.className}
          onClick={() => selectPattern(pattern.className)}
        >
          <Card.Img variant="top" src={pattern.previewImage} />
          <Card.Body>
            <Card.Title>{pattern.title}</Card.Title>
            <Card.Text>{pattern.description}</Card.Text>
          </Card.Body>
        </Card>
        </Col>
    ))}
        </Row>

        </Modal.Body>
      </Modal>
    </>
  )
}
ModalPatternChooser.propTypes = {
  show: PropTypes.bool,
  hide: PropTypes.func.isRequired,
  selectPattern: PropTypes.func.isRequired
}

class App extends React.Component {
  constructor (props) {
    super(props)

    this.state = { showChooser: false, choosenPattern: {} }

    this.locationClassName = this.locationClassName.bind(this)
    this.handleLocationChange = this.handleLocationChange.bind(this)
    this.handleHideChooser = this.handleHideChooser.bind(this)
    this.handleSelectPattern = this.handleSelectPattern.bind(this)
  }

  handleLocationChange (ev) {
    const location = ev.target
    const stateChange = { currentRegion: location.id, showChooser: true }
    console.log('this is:', location, location.id, stateChange)
    this.setState(stateChange)
    ev.stopPropagation()
    ev.nativeEvent.stopImmediatePropagation()
  }

  locationClassName (location, index) {
    const choosenPattern = this.state.choosenPattern[location.id]
    console.log(location.id, choosenPattern, this.props.classes[choosenPattern])
    if (choosenPattern) {
      return this.props.classes[choosenPattern]
    }
    return this.props.classes.default
  }

  handleHideChooser () {
    this.setState({ showChooser: false })
  }

  handleSelectPattern (choosenPattern) {
    const stateChange = { choosenPattern: { ...this.state.choosenPattern, [this.state.currentRegion]: choosenPattern }, showChooser: false, currentRegion: null }
    console.log(stateChange)
    this.setState(stateChange)
  }

  render () {
    console.log('render')
    return (
    <Container >
    <Navbar>
    <Navbar.Brand href="#home">
      <b>Click and Try</b>
    </Navbar.Brand>
    <Navbar.Brand href="https://kleiner-sonnenschein.de">by Kleiner Sonnenschein
      <img
        alt=""
        src={Logo}
        className="d-inline-block align-top"
      />{' '}
      </Navbar.Brand>
  </Navbar>
  <Row>
    <Col>
    <svg width="0" height="0">
      <defs>
        {patterns.map(pattern => (
          <>
          {pattern.pattern}
          </>
        ))}
      </defs>
    </svg>
    <SVGMap
      map={Shirt}
      onLocationClick={this.handleLocationChange}
      locationClassName={this.locationClassName}
      />
    </Col>
  </Row>
  <ModalPatternChooser
    show={this.state.showChooser}
    hide={this.handleHideChooser}
    selectPattern={this.handleSelectPattern}
  />
</Container>
    )
  }
}
App.propTypes = {
  classes: PropTypes.object
}

const patternsStyles = {
}
patterns.forEach(pattern => {
  patternsStyles[pattern.className] = pattern.style
})
const useStyles = createUseStyles(patternsStyles)

function Styled () {
  const classes = useStyles()
  return (<App classes={classes}/>)
}

export default Styled
